import React from 'react';
import { Icon } from 'glints-aries/lib/@next';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { CountryCodes, MobileAppPlatform } from 'src/common/enums';
import { pageCategoryMapper } from 'src/common/helpers';
import { useCountry } from 'src/common/hooks';
import useMount from 'src/common/hooks/useMount';
import pushGTMEvent from 'src/common/utils/google-tag-manager';
import { WindowUtils } from 'src/common/utils/windowUtils';
import { getAppAsset } from 'src/components/Button/DownloadMobileAppButton';
import {
  DesktopContainer,
  MobileOrTabletContainer,
} from 'src/components/ResponsiveContainers.sc';
import { fetchMyApplicationsWithFilter } from 'src/modules/Applications/actions';
import {
  applicationSortingOption,
  ApplicationStatusQuery,
} from 'src/modules/Applications/constants';
import {
  getUserApplicationsLoading,
  getUserApplicationsTotalCount,
} from 'src/modules/Applications/selectors';
import { useGetFakeChatMobileAppDownloadLink } from 'src/modules/CandidateMobileApp/Common/hooks/useHandleMobileAppRedirect';
import * as S from 'src/modules/Notification/Components/NotificationButton.sc';
import Dropdown from 'src/modules/Ugc/Components/Dropdown';

import { MenuItem } from './DesktopMenuComponents.sc';
import {
  Body,
  ChatButton,
  Container,
  DownloadContainer,
  Header,
  LinkButtonContainer,
  MobileIcon,
  NotificationDot,
  QRCode,
  Typography,
} from './FakeChatButtonMenu.sc';

type ComponentProps = {
  hasApplications: boolean;
};

const FakeChatButtonMenu = () => {
  const dispatch = useDispatch();
  const country = useCountry();
  const router = useRouter();
  const showComponent = [CountryCodes.VN, CountryCodes.ID].includes(country);
  const pageCategory = pageCategoryMapper(router.pathname);
  useMount(() => {
    dispatch(
      fetchMyApplicationsWithFilter(
        ApplicationStatusQuery.All,
        applicationSortingOption.MostRecent,
        1
      )
    );
  });

  const isLoading = useSelector(getUserApplicationsLoading);
  const totalCount = useSelector(getUserApplicationsTotalCount);
  const hasApplications = !isLoading && totalCount > 0;

  function handleOnClick(): void {
    const isMobile = WindowUtils.isMobile();
    pushGTMEvent('glints/GoogleTagManager/FAKE_CHAT_BUTTON_CLICKED', {
      payload: {
        placement: 'header',
        platform: isMobile ? 'Mobile Web' : 'Desktop',
        buttonCTA: hasApplications
          ? 'chat button icon with dot'
          : 'chat button icon without dot',
        pageCategory,
      },
    });
  }

  if (!showComponent) return null;

  return (
    <MenuItem>
      <Dropdown
        onDropdownOpen={handleOnClick}
        content={
          <Container>
            <Header>
              <FormattedMessage
                id="text-get-glints-app"
                defaultMessage="Get Glints App"
                tagName="span"
              />
            </Header>
            <MobileOrTabletContainer>
              <MobileComponent hasApplications={hasApplications} />
            </MobileOrTabletContainer>
            <DesktopContainer>
              <DesktopComponent hasApplications={hasApplications} />
            </DesktopContainer>
          </Container>
        }
      >
        <S.Button>
          <Icon name="ri-message-fill" width={24} height={24} />
          <If condition={hasApplications}>
            <NotificationDot />
          </If>
        </S.Button>
      </Dropdown>
    </MenuItem>
  );
};

const DesktopComponent = ({ hasApplications }: ComponentProps) => {
  const { locale: language } = useIntl();

  const androidAppAsset = getAppAsset(MobileAppPlatform.ANDROID, language);
  const iOSAppAsset = getAppAsset(MobileAppPlatform.IOS, language);

  return (
    <Body>
      <Typography variant="caption">
        <Choose>
          <When condition={hasApplications}>
            <FormattedMessage
              id="text-intall-app-fake-chat-has-applications"
              defaultMessage="Check for unread messages from employers or follow up with employers via chat"
            />
          </When>
          <Otherwise>
            <FormattedMessage
              id="text-intall-app-fake-chat"
              defaultMessage="Install our app to Chat and Apply instantly. Enjoy faster responses and easy follow up!"
            />
          </Otherwise>
        </Choose>
      </Typography>
      <Typography variant="overline">
        <FormattedMessage
          id="scan-or-download-fake-chat"
          defaultMessage="Scan the QR code or download from"
        />
      </Typography>
      <DownloadContainer>
        <QRCode>
          <img
            src="images/mobile/fake-chat-app-download-qr-code.png"
            alt="Get it on Google Play"
            width="80px"
          />
        </QRCode>
        <a
          href="https://bit.ly/fakechat_download_android"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={androidAppAsset.src}
            alt={androidAppAsset.alt}
            width="120px"
          />
        </a>
        <a href="https://apple.co/44SS3CL" rel="noreferrer" target="_blank">
          <img src={iOSAppAsset.src} alt={iOSAppAsset.alt} width="120px" />
        </a>
      </DownloadContainer>
    </Body>
  );
};

const MobileComponent = ({ hasApplications }: ComponentProps) => {
  const dynamicLinkURL = useGetFakeChatMobileAppDownloadLink();

  return (
    <Body>
      <MobileIcon
        src="images/mobile/mobile-app-icon-with-text.webp"
        alt="Glints Mobile App Icon"
      />

      <Typography variant="body2">
        <Choose>
          <When condition={hasApplications}>
            <FormattedMessage
              id="text-intall-app-fake-chat-has-applications"
              defaultMessage="Check for unread messages from employers or follow up with employers via chat"
            />
          </When>
          <Otherwise>
            <FormattedMessage
              id="text-intall-app-fake-chat"
              defaultMessage="Install our app to Chat and Apply instantly. Enjoy faster responses and easy follow up!"
            />
          </Otherwise>
        </Choose>
      </Typography>
      <LinkButtonContainer
        href={dynamicLinkURL}
        rel="noreferrer"
        target="_blank"
      >
        <ChatButton>
          <FormattedMessage
            id="text-chat-with-hrd"
            defaultMessage="Chat with HRD"
          />
        </ChatButton>
      </LinkButtonContainer>
    </Body>
  );
};

export default FakeChatButtonMenu;
