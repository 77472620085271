import { Brand } from 'glints-aries';
import styled from 'styled-components';

export const Logo = styled(Brand).attrs({
  asset: 'glints-black',
  alt: 'Glints Logo',
})`
  font-size: 1.25em;
`;

export const TapLokerLogo = styled(Brand).attrs({
  asset: 'glints-taploker-black',
  alt: 'Glints Taploker Logo',
})`
  font-size: 1.65em;
`;
