import { useCallback } from 'react';
import { isIOS } from 'react-device-detect';
import { useSelector } from 'react-redux';

import {
  DOWNLOAD_MOBILE_APP_DYNAMIC_LINK,
  JOB_DETAIL_MOBILE_APP_DYNAMIC_LINK,
  MOBILE_APP_CONFIG_DYNAMIC_LINK,
} from 'src/common/constants';
import { getIsAirbridgeLinksEnabled } from 'src/modules/Unleash/Selectors';

export function useHandleMobileAppRedirect(
  { redirectOnIOS } = { redirectOnIOS: false }
) {
  const redirect = useCallback(() => {
    window.open(DOWNLOAD_MOBILE_APP_DYNAMIC_LINK, '_blank');
  }, []);

  return isIOS && !redirectOnIOS ? null : redirect;
}

export function useHandleMobileAppOpenJobDetailPage(jobId: string) {
  const redirect = useCallback(() => {
    window.open(
      `${JOB_DETAIL_MOBILE_APP_DYNAMIC_LINK}${jobId}${MOBILE_APP_CONFIG_DYNAMIC_LINK}`,
      '_blank'
    );
  }, [jobId]);
  return redirect;
}

/**
 * This hook used to get the dynamicLinks for testing AirBridge integration
 * TODO (Tai): Removed it on the next iterable ingration with AirBridge
 */
export function useGetFakeChatMobileAppDownloadLink() {
  const isAirBridgeLinkEnabled = useSelector(getIsAirbridgeLinksEnabled);

  const firebaseURL =
    'https://dynamic-link.glints.com/page/fakechat_download_mweb';
  const airbridgeURL = 'https://loker.glints.com/jrhalx';

  return isAirBridgeLinkEnabled ? airbridgeURL : firebaseURL;
}
